
.UpscMainParent{
 margin-top: 100px;
}

.UpscCourseParent{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.UpscTitle{
    margin: 50px 100px 0;
    font-size: 25px;
    color: #27227A;
}
.UpscEachCourse{
    margin: 50px;
}
@media (max-width:1000px){
    .UpscTitle{
        width: 100%;
        text-align: center;
        margin-left:0;
        font-size: 150%;
    }
}
@media (max-width:400px){
    .UpscEachCourse{
        margin: 30px 0;
        
    }
    .UpscTitle{
        font-size: 130%;
    }
}
