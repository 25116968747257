*{
    font-family: Poppins, sans-serif;
    margin: 0;
    padding: 0;
}
.ReviewParent{
    box-sizing: border-box;
    width: 400px;
    min-height: 430px;
    background-color: white;
    /* border-radius: 10px; */
    /* border: 2px solid #CB2C31; */
    padding: 10px;

}
.ReviewTitle{
    position: relative;
    width: auto;
    padding: 10px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.PersonImage ,.PersonImage img{
    width: 50px;
    /* height: 50px; */
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    
}
.PersonImage{
    /* background-color: #CB2C31; */
    margin: 0 10px;

}
.time, .Name{
    font-size: 12px;
    margin: 5px 0;
}
.Name{
    display: flex;
    
}
.Name span{
    font-weight: 600;
    font-size: 14px;
    width: auto;
}
.PersonName{
    display: flex;
    margin: 0 10px;
    justify-content: center;
    max-width: 200px;
    flex-direction: column;
}
.ReviewStar{
    padding: 0 5px;
    width: 100px;
    font-size: 15px;
}
.ReviewBody{
    box-sizing: border-box;
    padding: 20px;
    font-size: 13px;
}
.ReviewBody p{
    text-align: left;
    line-height: 1.6;
}
.googleIcon{
    position: absolute;
    right: 30px;
    font-size: 30px;
}

@media (max-width:500px){
    .ReviewParent{
        width:90vw;
        min-height:60vh;
        height: auto;
    }
}
@media (max-width:420px) {
    .PersonName{
        width: auto;
    }
    .Name span{
        font-size: 120%;
    }
    .PersonName{
        margin: 0 10px;
    }
    .ReviewStar{
        width: auto;
        font-size: 80%;
        padding: 5px;
    }
    .ReviewBody{
        padding: 10px;
    }
    .ReviewBody p{
        font-size: 90%;
    }
    .time, .Name{
        font-size: 70%;
    }
    .Name span{
        font-size: 110%;
        word-spacing: 2px;
    }
    /* .ReviewParent{
        width: 90%;
        height: 50%;
    } */
}