@mixin paragraph{
    line-height: 1.8;
        font-size: 18px;
        text-align: justify;
        text-indent:80px;
}
$blue:#27227A;
*{
    font-family: Poppins, sans-serif;
    margin: 0;
    padding: 0;
    
}
.SyllabusPage{
    padding: 0;
    padding-top: 90px;
    background-color: #eeeeee;
    margin: 0;
    width: auto;
    height: auto;
}
.syllabus_container{
    // margin: 0 70px;
    // padding: 0 70px;
    margin: 0;
    padding: 0;
    // padding-bottom: 20px;
    .syllabus_container_heading{
        padding: 20px 0;
        .syllabus_container__heading_title{
            font-size: 30px;
            padding: 20px 0;
            color: $blue;
        }
        p{
            @include paragraph();
        }
    }
    .syllabus_container_body{
        .syllabus_container_body_title{
            div{
                color: $blue;
            font-size: 30px;
            padding: 10px 0;
            text-align: center;
            }
            margin: 50px 0;
        }
        .syllabus_container_body_middle{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: auto;
            // min-height: 60%;
            height:auto;
            padding-bottom:30px ;
            .syllabus_container_body_extensionPart{
                width: 650px;
                .extensionPart{
                    width: 100%;
                    transition: 2s;
                    .extension-heading{
                        padding: 10px 20px;
                        color: $blue;
                        font-size: larger;
                    }
                    .extensionPart_topic{
                    margin: 10px 0;
                    width: 100%;
                    height: 50px;
                    border-radius: 5px;
                    background-color: white;
                    &:hover{
                        background-color: white;
                        cursor: pointer;
                    }
                        
                        .extensionPart_topic_details{
                            width: 100%;
                            height: 100%;
                            display: flex;
                            box-sizing: border-box;
                            padding: 20px;
                            justify-content: space-between;
                            align-items: center;
                           p{
                            font-size: 18px;
                            color:#27227A;
                           }
                        }
                    }
                    .extensionPart_content{
                        width: 100%;
                        background-color: white;
                        transition: 0.3s;
                        height: 0;
                        // position: relative;
                        overflow: hidden;
                        border-radius: 5px;
                        &:hover{
                            box-shadow: 2px 2px 10px #26227aa6;
                        }
                        .extensionPart-content-box{
                            // position: absolute;
                            box-sizing: border-box;
                            list-style-type: none;
                            padding: 10px 30px;
                            // margin: 20px;
                            width: 100%;
                            div{
                                width: 100%;
                                margin: 15px 0;
                                font-size: 15px;
                            }
                            div::before{
                                content: "\2022";
                                color: #27227A;
                                font-size: 20px;
                                font-weight: bolder;
                                display: inline-block; 
                                width: 1em;
                                // margin-left: -1em;
                            }
                        }
                        
                    }
                }
                .syllabus_container_button{
                    margin: 30px;
                    // margin-right: 70px;
                    display: flex;
                    justify-content: flex-end;
                    
                    .button_previouslink{
                        text-decoration: none;
                        color: rgb(179, 179, 179);
                        font-size: 13px;
                        padding: 20px;
                        cursor: pointer;
                    }
                    a{
                        
                        text-decoration: none;
                    }
            
                }
            }
            .syllabus_container_body_coursebox_parent{
                width: auto;
                // min-width: 300px;
                margin-left: 80px;
                .syllabus_container_body_coursebox{
                    width: 100%;
                    height: auto;
                    margin: 0;
                    // margin-left: 40px;
                    border-radius: 5px;
                    background-color: white;
                    padding: 20px;
                    
                    // box-shadow: 2px 2px 10px #26227aa6;
                    // border: #27227A 1px  solid;
                    .coursebox_title{
                        // margin-top: 20px;
                        font-size: 25px;
                        border-radius: 5px;
                        padding: 10px;
                        color:white;
                        text-align: center;
                        background-color: $blue;
    
                    }
                    .coursebox_icons{
                        // text-align: center;
                        // background-color: aqua;
                        margin: 20px 30px;
                        
                        font-size: 18px;
                        .coursebox-each-icons{
                            color: $blue;
                            padding-top: 15px;
                            font-size: 25px;
                            margin-right: 20px;
                            text-align: center;
                            
                        }
                    }
                }
            }
           
        }
        
    }
    
}
@media (max-width:950px){
    .syllabus_container{
        // margin: 30px;
        // padding: 20px;
        .syllabus_container_body{
            .syllabus_container_body_middle{
                flex-direction: column-reverse;
                width: auto;
                max-height: auto;
                justify-content: center;
                align-items: center;
                // background-color: aqua;
                .syllabus_container_body_coursebox_parent{
                    // margin:20px;
                    margin-bottom: 50px;
                    
                }
                
            } 
        }
        
    }
    
    
}
@media (max-width:930px){
    .syllabus_container{
        .syllabus_container_heading{
            .syllabus_container__heading_title{
                font-size: 25px;
                text-align: center;
            }
            p{
                font-size: 16px;
            }
        }
        .syllabus_container_body{
            .syllabus_container_body_title{
                div{
                    font-size: 25px;
                    text-align: center;
                }
            }
            .syllabus_container_body_middle{
                
                .syllabus_container_body_extensionPart{
                    .extensionPart{
                        width: 100%;
                    }
                .syllabus_container_body_coursebox{
                    padding: 20px;
                    .coursebox_title{
                        font-size: 20px;
                        
                    }
                    .coursebox_icons{
                        
                        font-size: 16px;
                    }
                }
                .syllabus_container_button{
                    box-sizing: border-box;
                    width: auto;
                    // padding: 0 70px;
                    justify-content: center;
                    flex-direction: column-reverse;
                    a{
                        display: block;
                        text-align: center;
                        text-decoration: none;
                    }
                }
            }
        }
            
            
        }
        
    }
}
@media (max-width:650px){
    .syllabus_container{
        
        .syllabus_container_body{
            .syllabus_container_body_title{
                div{
                    font-size: 120%;
                }
            }
            .syllabus_container_body_middle{
               justify-content: center;
                // .syllabus_middle_content{
                    
                // }
                .syllabus_container_body_extensionPart{
                    width: 100%;
                    .extensionPart{
                        .extension-heading{
                            font-size: 95%;
                        }
                        .extensionPart_topic{
                            .extensionPart_topic_details{
                                p{
                                    font-size: 95%;
                                }
                            }

                        }
                        .extensionPart_content{
                            .extensionPart-content-box{
                                div{
                                    font-size: 90%;
                                    text-align: left;
                                }
                            }
                        }
                    }
                    .syllabus_container_button{
                        box-sizing: border-box;
                            padding:auto;
                    }
                }
                
                .syllabus_container_body_coursebox_parent{
                    width: 100%;
                    min-height: 300px;
                    height: auto;
                    box-sizing: border-box;
                    // padding: 10px 20px ;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    margin-bottom: 30px;
                    .syllabus_container_body_coursebox{
                        width: 80%;
                        margin: 0;
                        padding: 10px;
                        // clear: left;
                        .coursebox_title{
                            font-size: 120%;
                        }
                        .coursebox_icons_box{
                            .coursebox_icons{
                                font-size: 100%;
                                .coursebox-each-icons{
                                    font-size: 120%;  
                                }
                            }
                            
                        }
                    }
                }
               
            } 
        }
        
    }
}
@media (max-width:500px){
    
    
   
    .syllabus_container{
        margin: 30px 15px 0;
        padding:0;
        .syllabus_container_body{
.syllabus_container_body_middle {
    .syllabus_container_body_coursebox_parent{
        .syllabus_container_body_coursebox {
            .coursebox_title{
                font-size: 100%;
            }
                .coursebox_icons_box {
                    .coursebox_icons {
                        font-size: 90%;
                    }
                }
            }
    }
        
    }
        }
       
        .syllabus_container_button{
            padding: 0;
        }
    }
    
    
}