*{
   margin: 0;
   padding: 0; 
}
.ContactPage, #PopupPage{
    margin: 0;
    padding: 0;
}


.PopupPageParent{
    position: fixed;
    top:0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.46);
    z-index: 100;
    margin: 0;
    padding: 0;
}

.PopupPageMain{
    /* height: 450px; */
    width: 350px;
    border-radius: 10px;
    border: 1px solid #CB2C31;
    background-color: white; 
    padding: 10px;
    
}
.Heading{
    margin:10px ;
}
.HeadingImage{
    position: relative;
    text-align: center;
    
}
.HeadingImage img{
    width: 200px;
    height: 50px;
   
}
.btn-close{
    position: relative;
    top:10px;
    left: 320px;
    color: #27227A;
    font-size: 18px;
    cursor: pointer;
    transition: 2s ease-in-out;
    z-index: 10;
}
/* .btn-close :hover{
 transform: rotate(90deg)
} */
.FormElement{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 40px 20px;
    border-width:0 0 2px;
    border-style: solid;
    border-color: #27227A;
    padding-bottom: 5px;
}
.SelectList{
    width: auto;
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: flex-start;
    flex-direction: column;
}
.CourseMenu{
    display: flex;
    align-items: center;
    flex-direction: row;
    /* height: 25px; */
    width: 88%;
    margin: 0;
    
}
.popupCourseTitle{
    width: 200px;
    font-size: 12px;
    color: #27227A;
    
    /* margin-left: 10px; */
    text-align: left;
    padding: 0;
    padding-right: 30px;
    padding-left: 20px;
    cursor: pointer;
}
.InputType{
    outline: 0;
    border-width: 0;
    width: 90%;
    margin-left: 20px;
    color: #27227A;
    font-size: 16px;
    
}
::placeholder {
    color: #5c5c5c;
    /* font-size: 16px; */
}
:-ms-input-placeholder{
    color: #27227A;
    font-size: small;
}
::-ms-input-placeholder { 
    color: #27227A;
    font-size: small;
}
.Icon{
    color: #27227A;
    height: 15px;
    width: 15px;
}
.Options{
    display: none;
    width: 88%;
    z-index: 2;
    position: absolute;
   margin: 22px;
   /* height: auto; */
   height: 180px;
   overflow-y: scroll;
   scroll-behavior: smooth;
   background-color: #CB2C31;
   opacity: 0.9;
   scrollbar-width:thin;
   scrollbar-color:white;
}
.option{
    width: auto;
    font-size: 12px;
    color: white;
    padding: 15px 20px;
    transition: 1s;
    
}
.option:hover{
   cursor: pointer;
   background-color: #cb2c3161;
}

/* @media(max-width:850px){
    .FormElement{
        margin: 20px;
    }
} */

@media (max-width:400px){
    .PopupPageMain ,#PopupPage{
        box-sizing: border-box;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
       
        
    }
   
    .PopupPageParent{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        margin: 0;
        padding: 0 10px;
       
    }
    .btn-close{
        height: 0px;
        width: 10px;
        left: 90%;
        font-size: 120%;
    }
    
    
    .Heading{
        width: 90%;
        height: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
   
    .FormElement{
        width: 80%;
        margin: 8% 10%;
        
    }
    .InputType{
        margin-left: 15px;
        
    }
}