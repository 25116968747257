.RegionalCourseParent{
    margin-top: 100px;
}
.RegionalCourse{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.RegionalEachCourse{
    margin: 50px;
}
.RegionalCourseTitle{
    margin: 50px 100px 0;
    font-size: 25px;
    color: #27227A;
}

@media (max-width:1000px){
    .RegionalCourseTitle{
        width: 100%;
        text-align: center;
        margin-left:0;
        font-size: 150%;
    }

}
@media (max-width:400px){
    .RegionalCourseTitle{
        font-size: 130%;
    } 
}