.AchieverParent {
  /* margin: 20px 0; */
  padding: 30px 0;
  /* margin-top: 50px; */
  background-color: rgb(236, 236, 236);
}
.AchieverTitle {
  color: #27227a;
  font-size: 25px;
  margin: 0;
  margin-left: 150px;
}
.AchieverDetails {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.AchieverImage,
.AchieverImage img {
  width: 300px;
  height: 300px;
  border-radius: 20px;
}
.AchieverImage img {
  object-fit: cover;
}
.AchieverImage {
  margin: 30px 50px;
  padding-top: 40px;
}
.AchieverContent {
  padding: 30px;
  max-width: 550px;
}
.AchieverName {
  color: #27227a;
  font-size: 25px;
  font-weight: 500;
  /* margin: 20px 0; */
}
.AchieverRank {
  color: #27227a;
  margin: 30px 0;
  font-size: 18px;
}
.AchieverDescription {
  font-size: 18px;
  margin: 20px 0;
}
.AchieverCurrentDetails {
  line-height: 2;
  text-align: justify;
}
@media (max-width: 1020px) {
  .AchieverTitle {
    width: auto;
    text-align: center;
    margin: 0;
  }
  .AchieverImage {
    padding: 0;
  }
}
@media (max-width: 450px) {
  .AchieverTitle {
    font-size: 130%;
  }
  .AchieverImage {
    width: 70vw;
    height: 30vh;
    margin: 20px;
    padding: 0;
  }
  .AchieverImage img {
    width: 100%;
    height: 100%;
  }
  .AchieverContent {
    padding: 15px;
  }
  .AchieverCurrentDetails {
    font-size: 90%;
    line-height: 1.7;
  }
}
