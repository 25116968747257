/* -webkit-user-select: none; /* Safari */
  /* -ms-user-select: none; IE 10 and IE 11
  user-select: none; */


*{
    margin: 0;
    padding: 0;
}
::-webkit-scrollbar {
   height: 5px;
}
.CourseSlide{
    /* width: 100%; */
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;  
    /* margin: 0 13%; */
}
.CourseSlideMain{
    position: relative;
    width: 82%;
    height: 600px;
    /* background-color: rgb(224, 224, 224); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 50px;
    z-index: 2;
    overflow: hidden;
    /* overflow-x: scroll; */
    
}

.CourseLeftClick , .CourseRightClick{
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: 100px;
    max-height: 100px; */
    border-radius: 50%;
    font-size: 30px;
    /* background-color: #CB2C31; */
    text-align: center;
    color: #CB2C31;
    border:  1px solid #CB2C31;
    /* font-weight: 50; */
    /* box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.587); */
    box-shadow: 1px 1px 5px 0px #cb2c317c;
    cursor: pointer;
    z-index: 3;
    
}

.CourseSlideContainers{
    width: auto;
    /* background-color: #CB2C31; */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;  
    left: 0px;
    transition: 0.5s;
    cursor: pointer;
    /* pointer-events: none; */
   
}
@media (max-width:1190px){
    .CourseSlideMain{
        width: 780px; 
    }
}
@media (max-width:950px){
    .CourseSlideMain{
        width: 390px; 
    }
}
@media(max-width:550px){
   .CourseLeftClick, .CourseRightClick{
    display: none;
   } 
   .CourseSlideMain{
    width: 390px;
    margin: 10px;
   }
}
@media (max-width:400px){
    .CourseSlideMain{
        width: 90vw;
        margin: 0;
        /* margin: 10px; */
        overflow-x: scroll;
       }
       .CourseSlideContainers{
        left:0px;
       }
}