@charset "UTF-8";
* {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}

.SyllabusPage {
  padding: 0;
  padding-top: 90px;
  background-color: #eeeeee;
  margin: 0;
  width: auto;
  height: auto;
}

.syllabus_container {
  margin: 0;
  padding: 0;
}
.syllabus_container .syllabus_container_heading {
  padding: 20px 0;
}
.syllabus_container .syllabus_container_heading .syllabus_container__heading_title {
  font-size: 30px;
  padding: 20px 0;
  color: #27227A;
}
.syllabus_container .syllabus_container_heading p {
  line-height: 1.8;
  font-size: 18px;
  text-align: justify;
  text-indent: 80px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_title {
  margin: 50px 0;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_title div {
  color: #27227A;
  font-size: 30px;
  padding: 10px 0;
  text-align: center;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  height: auto;
  padding-bottom: 30px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart {
  width: 650px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart {
  width: 100%;
  transition: 2s;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extension-heading {
  padding: 10px 20px;
  color: #27227A;
  font-size: larger;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_topic {
  margin: 10px 0;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: white;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_topic:hover {
  background-color: white;
  cursor: pointer;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_topic .extensionPart_topic_details {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_topic .extensionPart_topic_details p {
  font-size: 18px;
  color: #27227A;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_content {
  width: 100%;
  background-color: white;
  transition: 0.3s;
  height: 0;
  overflow: hidden;
  border-radius: 5px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_content:hover {
  box-shadow: 2px 2px 10px rgba(38, 34, 122, 0.6509803922);
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_content .extensionPart-content-box {
  box-sizing: border-box;
  list-style-type: none;
  padding: 10px 30px;
  width: 100%;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_content .extensionPart-content-box div {
  width: 100%;
  margin: 15px 0;
  font-size: 15px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_content .extensionPart-content-box div::before {
  content: "•";
  color: #27227A;
  font-size: 20px;
  font-weight: bolder;
  display: inline-block;
  width: 1em;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_button {
  margin: 30px;
  display: flex;
  justify-content: flex-end;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_button .button_previouslink {
  text-decoration: none;
  color: rgb(179, 179, 179);
  font-size: 13px;
  padding: 20px;
  cursor: pointer;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_button a {
  text-decoration: none;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent {
  width: auto;
  margin-left: 80px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox {
  width: 100%;
  height: auto;
  margin: 0;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_title {
  font-size: 25px;
  border-radius: 5px;
  padding: 10px;
  color: white;
  text-align: center;
  background-color: #27227A;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_icons {
  margin: 20px 30px;
  font-size: 18px;
}
.syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_icons .coursebox-each-icons {
  color: #27227A;
  padding-top: 15px;
  font-size: 25px;
  margin-right: 20px;
  text-align: center;
}

@media (max-width: 950px) {
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle {
    flex-direction: column-reverse;
    width: auto;
    max-height: auto;
    justify-content: center;
    align-items: center;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent {
    margin-bottom: 50px;
  }
}
@media (max-width: 930px) {
  .syllabus_container .syllabus_container_heading .syllabus_container__heading_title {
    font-size: 25px;
    text-align: center;
  }
  .syllabus_container .syllabus_container_heading p {
    font-size: 16px;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_title div {
    font-size: 25px;
    text-align: center;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart {
    width: 100%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_body_coursebox {
    padding: 20px;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_body_coursebox .coursebox_title {
    font-size: 20px;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_body_coursebox .coursebox_icons {
    font-size: 16px;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_button {
    box-sizing: border-box;
    width: auto;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_button a {
    display: block;
    text-align: center;
    text-decoration: none;
  }
}
@media (max-width: 650px) {
  .syllabus_container .syllabus_container_body .syllabus_container_body_title div {
    font-size: 120%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle {
    justify-content: center;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart {
    width: 100%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extension-heading {
    font-size: 95%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_topic .extensionPart_topic_details p {
    font-size: 95%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .extensionPart .extensionPart_content .extensionPart-content-box div {
    font-size: 90%;
    text-align: left;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_extensionPart .syllabus_container_button {
    box-sizing: border-box;
    padding: auto;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent {
    width: 100%;
    min-height: 300px;
    height: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 30px;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox {
    width: 80%;
    margin: 0;
    padding: 10px;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_title {
    font-size: 120%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_icons_box .coursebox_icons {
    font-size: 100%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_icons_box .coursebox_icons .coursebox-each-icons {
    font-size: 120%;
  }
}
@media (max-width: 500px) {
  .syllabus_container {
    margin: 30px 15px 0;
    padding: 0;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_title {
    font-size: 100%;
  }
  .syllabus_container .syllabus_container_body .syllabus_container_body_middle .syllabus_container_body_coursebox_parent .syllabus_container_body_coursebox .coursebox_icons_box .coursebox_icons {
    font-size: 90%;
  }
  .syllabus_container .syllabus_container_button {
    padding: 0;
  }
}/*# sourceMappingURL=syllabusStyle.css.map */