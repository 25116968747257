*{
    margin: 0;
    padding: 0;
}
/* temporary */
/* #CourseHtml{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: auto; */
    /* padding: 20px; */
/* } */
.CourseParent{
    height: 560px;
    /* height: 450px; */
    width: 350px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    /* margin: 20px 50px; */
    /* border: 2px solid; */
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.CourseParent:hover{
    box-shadow: 0 4px 8px 0 #cb2c3139, 0 6px 20px 0 #cb2c3139;
}
 .CourseParent img {
    height: 50%;
    /* width: 350px; */
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    object-fit: cover;
    margin: 0;
    padding: 0;
    top:0
    
}
.CoursePart2{
    /* background-color: #27227A; */
    box-sizing: border-box;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 30%;
}
.CourseTitle{
    font-size: 17px;
    width: auto;
    text-align: center;
    padding: 20px 10px;
    margin: 0px;
    font-weight: bold;
   
}

.CourseDetails{
    color: #27227A;
    width: auto;
    font-size: 15px;
    margin: 10px 20px;
    background-color: #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    /* padding: 10px; */
    
}
.CourseContent{
    padding: 0 30px;
    text-align: justify;
    font-size: 16px;
    line-height: 1.5;
}
.courseDuration{
    display: flex;
    width: 260px;
    height: auto;
    justify-content: center;
    margin: 25px 5px;
    flex-wrap: wrap;
}
.duration{
    display: flex;
    margin: 5px;
    font-size: 20px;

    /* background-color: #CB2C31; */
}
/* Optional Course  starts*/
.optionalDuration{
    margin: 20px 5px;
    display: flex;
}
.optionalDuration{
    margin: 20px 20px;
    justify-content: flex-start;
}
.optional{
    width: 150px;
    font-size: 15px;
    /* font-weight: 600; */
    margin: 0;
    text-align: left;
    line-height: 1.5;
    font-weight: bold;
}
.optional p{
 width: 100px;
 padding: 0;
 margin: 0;
}
/* Optional Course  ends*/
.duration p{
   margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
}
.ButtonComponent{
    box-sizing: border-box;
    width: 80%;
    height: 50px;
    
    /* display: flex;
    justify-content: center; */
    margin:20px 10px;
}
.ButtonComponent Link{
    text-decoration: none;
}
@media (max-width:400px){
    /* #CourseHtml{
        padding: 10%;
    } */
    /* .CourseParent{
        width: 90%;
        height: 50%;
    } */
    .CourseParent{
        /* height: auto; */
        height: 420px;
        width: 80vw;
        padding: 0;

    }
    .CourseParent img {
        height: 50%;
        width: 100%;  
        object-fit: cover;
    }
    .CourseTitle{
        padding: 0;
        margin: 8px 0;
    }
    .CourseTitle p{
        font-size: 105%;
        
    }
    .CourseContent{
        font-size: 80%;
        padding: 10px;
        line-height: 1.5;
        /* margin: 0 10px; */
    }
    .CoursePart2{
        box-sizing: border-box;
        font-size: 90%;
        /* width: 100%; */
        width: auto;
        padding: 10px;
        margin: 0 10px;
        height: auto;
        /* height: 90%; */
    }
    .CourseDetails{
        padding: 10px ;
        margin: 0;
    }
    .courseDuration{
        width: auto;
        margin: 0 10px;
    }
    .duration {
        font-size: 80%;
        margin: 5px;
    }
    .duration p{
        font-size: 100%;
    }
    /* .CourseParent{
        height: 530px;
    } */
    .ButtonComponent{
        height: 30px;
        margin: 15px 5px;
        width: 70%;
    }
}
