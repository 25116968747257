.GroupDiscussionEachParent{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.GroupDiscussionTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    color:  #27227A;
    font-size: 25px;
    margin: 20px 0;
}
.GroupSlide , .GroupSlide img{
 width:350px;
 height: 500px;
 border-radius: 5px;
 object-fit: cover;
}
.GroupSlide{
    margin: 30px;
}
.GroupSlideContainer{
    display: flex;
    justify-content: center;

}

.GroupDiscussionParent{
    /* padding: 20px; */
    width: 100%;
    height: 730px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
}

.GroupSlideLeft, .GroupSlideRight{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 30px; */
    font-size: 40px;
    color: #CB2C31;
    cursor: pointer;

}
.GroupSlideFrameParent{
    width: 900px;
    height: 650px; 
    display: flex;
    align-items: center;
    justify-content: center; 
}
.GroupSlideFrame{
overflow: hidden;
width: 830px;
height: 650px;
position: absolute;
margin: 10px;
/* background-color: #5b5890; */
/* transition: 5s; */
}     
.GroupContainer{
    position: absolute;
    display: flex;
    /* background-color: #565571; */
    width: auto;
    height: auto;
    left:0px;
    transition: 0s ease-in;
    pointer-events: none;
    
}

/* @media (max-width:1015px){
    .GroupSlideFrame{
        width:410px;
    }
    .GroupDiscussionTitle{
        display: none;
    }
} */

@media (max-width:1000px){
    .GroupDiscussionParent{
        width: 100%;
    }
}
@media (max-width:600px){
    .GroupSlideLeft, .GroupSlideRight{
        display: none;
    }
}
@media (max-width:450px){
    .GroupSlideContainer{
        width:auto;
    }
    .GroupSlide {
        width:83vw;
        height: 50vh;
        padding: 10px 20px;
        margin: 0;
    }
    .GroupSlide img{
        width:100%;
        height: 100%;
    }
}