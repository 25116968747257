/* RegionalExamAchiever Style starts */
.RegionalExamAchiever {
  margin: 0;
  padding: 0;
  margin: 0 70px;
  width: 750px;
  /* height: 300px; */
}
.RegionalExamMainParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
  user-select: none;
}
.RegionalAchieverImage,
.RegionalAchieverImage img {
  width: 200px;
  height: 200px;
  border-radius: 8px;
}
.RegionalAchieverImage img {
  object-fit: cover;
}
.ExamAchieverName {
  font-size: 23px;
  margin: 20px 30px;
  color: #27227a;
}
.RExamRank {
  margin: 20px 30px;
  font-size: 20px;
  color: #27227a;
}
.RExamDetails {
  margin: 20px 30px;
  text-align: justify;
  line-height: 2;
  font-size: 18px;
  user-select: none;
}
/*  RegionalExamAchiever Style  ends*/

/* RegionalExamHtml page starts */
.RegionalExamPageComponent {
  /* margin: 20px 0; */
  padding: 30px 0;
  background-color: #dddddd;
}
.RegionalExamTitle {
  margin: 30px 100px;
  font-size: 25px;
  color: #27227a;
}
#RegionalContentOnly {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; */
  padding-left: 350px;
  margin: 30px;
  margin-top: 50px;
  user-select: none;
}
/* .regional_Achiever__contentonly {
  /* display: flex; */
/* width: 750px; */
/* flex-direction: column; */
/* justify-content: center; */
/* } */
@media (max-width: 1098px) {
  .RegionalExamTitle {
    margin: 30px 0;
    text-align: center;
  }
  .ExamAchieverName,
  .RExamRank {
    text-align: center;
  }
  #RegionalContentOnly {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 7px;
  }
}
@media (max-width: 700px) {
  .RegionalExamAchiever {
    width: auto;
    margin: 7px;
  }
  .RExamDetails {
    margin: 10px;
    line-height: 1.7;
    font-size: 90%;
  }
  .RegionalExamMainParent {
    margin: 20px 0;
  }
}
@media (max-width: 500px) {
  .RegionalExamTitle,
  .ExamAchieverName {
    font-size: 130%;
  }
  .ExamAchieverName {
    font-size: 110%;
  }
  .RExamRank {
    font-size: 90%;
  }
}
