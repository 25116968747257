*{
    font-family: Poppins, sans-serif;
    margin: 0;
    padding: 0;
}
.LandingPageParent{
    margin-top: 70px;
    position: relative;
    max-width: 1920px; 
    height: auto; 
    height: auto;
}
.UpscImage{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.UpscImage img{
    width: 100%;
    height: 100%; 
    object-fit: cover;
}
.UpscContent{
    z-index: 5;
    max-width:1920px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.615);
    /* background-color: #cb2c3144; */
    padding: 100px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
}
.UpscHeading{
    max-width: 700px;
    margin-right: 50px; 
    color: white;
}

.UpscHeading p, .UpscHeading h1{
    text-align: justify;
    padding: 20px;
    /* color: black; */
    /* font-weight: 600; */
    line-height: 2;
    /* margin: 10px; */  
}
.UpscHeading p{
    font-size: 20px;
    font-weight: 600;
    word-spacing: 1.5;
}
.UpscHeading h1{
    font-size: 30px;
    text-align: left;
    font-weight: 800;
    
}
.UpscReview{
    margin-left: 60px;
    /* box-shadow: 1px 1px 5px 0px #cb2c3188; */
    /* border-radius: 10px; */

}
.LandingButton{
    margin: 20px 15px;
}

@media (max-width:1170px){
    .UpscContent{
        padding: 30px 10px;
    }
    .UpscHeading{
        margin: 0;
    }
  .UpscHeading h1{
    text-align: center;
  }
  .UpscReview{
    margin: 0;
  }
  .LandingButton{
    display: flex;
    justify-content: center;
}
}

@media (max-width:950px){
    .UpscHeading,.UpscReview{
        margin: 10px;
    }
    .UpscReview{
        width: auto;
        height: auto;
    }
    .UpscHeading p, .UpscHeading h1{
        line-height: 1.6;
    }
    .UpscHeading p{
        margin: 0;
        font-size: 90%;
        padding: 20px 10px;
    }
    .UpscHeading h1{
        font-size: 160%;
        padding: 20px 10px;
    }
}
@media (max-width:450px){
    .UpscHeading{
        font-size: 90%;
    }
    .UpscContent{
        height: auto;
        padding: 10px;
    }

}