*{
    margin: 0;
    padding: 0;
}
#AutoHtml{
    width: 400px;
    height: 450px;
}
.slide_frame_parent{
    width: 400px;
        height: 450px;
        position: relative;  
}
.SlideFrame_container{
    width: 400px;
    height: 450px;
    position: absolute;
    
    z-index: 10;
    background-color: rgba(116, 116, 116, 0.229);
    
}
.SlideFrame-container-icons{
    height: 450px;
display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
        color: rgb(144, 144, 144);
}
.SlideFrame{
    background-color: rgb(255, 255, 255);
    /* z-index: 10; */
    position: absolute;
    width: 400px;
    height: 450px;
    /* top: 50px;
    left: 250px; */
    /* border: 4px solid green; */
    /* background-color: white; */
    overflow: hidden;
    border-radius: 5px;
}
.SlideContainer{
   
    width: auto;
    height: auto;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    
}

@media (max-width:500px){
    .SlideFrame_container, .SlideFrame-container-icons, .SlideFrame, .SlideFrame{
        height: auto;
        min-height: 470px;
        width: 300px;
        border-radius: 4px;
    }
            
}