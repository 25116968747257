/*  */
.SmallGroupDiscussionContainer{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    
}
.SmallGroupTitle{
    text-align: center;
    font-size: 20px;
    margin: 10px;
    color:  #27227A;
}
.SmallGroupImage ,.SmallGroupImage img{
    width: 280px;
    height: 400px;
    object-fit: cover;
    border-radius: 4px;
}
@media (max-width:400px){
    .SmallGroupTitle{
        font-size: 120%;
    }
}
/*  */

/*  */

.SGDContainer{
    display: flex;
    width: 100%;
    justify-content: center;

}
.SmallGroupSlideFrame{
    position: relative;
    display: flex;
    width: 300px;
    height: 500px;
    overflow: hidden;
    margin: 20px;
}
.SmallSlideContainer{
    position: absolute;
    width: auto;
    display: flex;
    height: 500px;
    transition: 0.5s;
}
@media (max-width:600px) {
    .SmallGroupSlideFrame{
        overflow-x: scroll;
    }
}