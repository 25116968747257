* {
  margin: 0;
  padding: 0;
}
.AboutUsMain {
  margin-top: 120px;
}
.AboutContent1 {
  margin: 50px 100px;
}
.AboutHeading {
  color: #27227a;
  font-size: 30px;
}
.AboutParagraph {
  margin: 20px 0;
  text-indent: 100px;
  line-height: 2;
  font-size: 18px;
  text-align: justify;
}
.FounderDetailsParent {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  flex-wrap: wrap;
}
.FounderDetails {
  display: flex;
  /* justify-content: center; */
  flex-direction: row;
  /* flex-wrap: wrap; */
  margin: 30px 0;
  width: 1020px;
  /* height: 350px; */
}
.FounderPhoto {
  display: flex;
  /* justify-content: center; */
  width: auto;
  height: auto;
}
.Photo {
  height: 100%;
  /* margin-right: 10px; */
}
.Photo img {
  /* position: absolute; */
  max-height: 385px;
  max-width: 300px;
  /* max-width: inherit; */
  object-fit: cover;
  border-radius: 10px;
  /* z-index: 0; */
  /* position: absolute; */
}
.FounderFrame {
  max-height: 385px;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0px -10px 30px #ccc;
  /* z-index: 5; */
  width: auto;
  /* max-height: 350px; */
  /* max-width: 280px; */
  /* position: relative; */
  /* border: 1px solid rgba(187, 20, 20, 0.363); */
  /* background-color: #26227a2f; */
  /* background: linear-gradient(to bottom,rgba(240, 0, 0, 0.003) ,rgba(0, 0, 0, 0.147)); */
  /* background: rgba(0, 0, 0, 0.847); */
  /* background:rgba(0, 0, 0, 0.003); */
}

.FounderContent {
  /* display: flex;  */
  /* position: relative; */
  /* flex-direction: column; */
  /* height: 350px; */
  /* width: 400px; */
  width: auto;
  padding: 10px;
  margin-left: 50px;
  margin-right: 20px;
  /* color: rgb(224, 224, 224);
  background-color: #380000; */
}
.FounderName {
  font-size: 25px;
  margin-bottom: 20px;
  color: #27227a;
}
.degree {
  font-size: 12px;
}
.FounderTitle {
  font-size: 20px;
  color: #27227a;
}
.FounderPara {
  text-align: justify;
  line-height: 1.8;
  font-size: 18px;
  margin: 20px 0;
}
.AboutIcon {
  display: flex;
  margin-top: 30px;
}
.EachAboutIcon {
  /* width: 30px;
  height: 30px;
   border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #8a8a8a 1px solid; */
  text-align: center;
  margin: 0 15px;
  font-size: 20px;
  /* text-align: center; */
  color:#8a8a8a;
  text-decoration: none;
  transition: 0.5s;
}
.EachAboutIcon:hover{
 color: #27227a;
 transform: scale(1.2);
/* border: #27227a 1px solid; */
}

@media (max-width: 850px) {
  .FounderPhoto{
max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
}
/* .FounderFrame{
  max-width: 200px;
  max-height: 257px;
} */
.Photo img{
  width: 100%;
  height: 100%;
}

  .FounderDetails {
    width: auto;
    height: auto;
    /* max-width: 90vw;
        max-height: 100vh; */
    /* align-items: center;
        justify-content: center; */
    flex-direction: column;
  }
  .FounderContent {
    height: auto;
    width: auto;
    margin: 0;
    /* margin: 0 20px; */
  }
  .FounderName,
  .FounderPara,
  .FounderTitle,
  .AboutIcon {
    display: flex;
    justify-content: center;
  }
  .FounderName {
    width: 100%;
    font-size: 110%;
  }
  .degree {
    font-size: 50%;
    line-height: 3;
    margin: 0 5px;
  }
  .FounderTitle {
    font-size: 100%;
  }
  .FounderPara{
    font-size: 90%;
  }
  .FounderDetails {
    display: flex;
    justify-content: center;
  }
 
  .AboutHeading {
    display: flex;
    justify-content: center;
    font-size: 150%;
  }
  .AboutParagraph {
    text-indent: 0;
    margin: 20px 0;
    font-size: 100%;
    line-height: 1.8;
  }
  .AboutContent1 {
    margin: 0 30px;
  }
}
@media (max-width: 500px) {
  .FounderPhoto {
      max-width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
    }
  
    .FounderFrame {
      max-width: 100%;
        max-height: 100%;
    }
  .FounderDetails {
    max-width: auto;
    max-height: auto;
  }
  .FounderContent {
    margin: 0;
  }
}
@media (max-width: 400px) {
  .FounderFrame,
  .Photo {
    width: 70vw;
    height: auto;
    margin: 0;
  }
  .Photo img {
    width: 100%;
    /* height: auto; */
    height: 100%;
  }
}
