.ContactFormContainer{
    height: auto;
    width: 300px;
    padding: 30px;
    border: 1px solid #CB2C31;
    border-radius: 4px;
}
.ContactFormTitle{
    display: flex;
    justify-content: center;
}
.ContactFormTitle{
    text-align: center;
    font-size: 20px;
    color: #27227A;
}
.ContactFormTitle img{
    margin: 0 10px;
    width: 30px;
    height: 30px;
}

@media (max-width:380px){
    .ContactFormContainer{
        padding: 10px;
    }
    
}