.SmallButtonParent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 50px;
  background-color: #cb2c31;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}
.SmallButtonParent p {
  margin: 5px;
}
.SmallButtonParent:hover {
  background-color: white;
  outline: 1px solid #cb2c31;
  color: #cb2c31;
  cursor: pointer;
}

@media (max-width: 450px) {
  .SmallButtonParent {
    width: 120px;
    height: 45px;
    font-size: 90%;
  }
  .SmallButtonParent p {
    /* margin: 5px; */
    margin: 0;
    padding: 0;
  }
}
