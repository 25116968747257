.SpokenEnglishPageParent{
    margin-top: 100px;

}
.SPCourses{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.SPTitle{
    margin: 50px 100px 0;
    font-size: 25px;
    color: #27227A;
}
.SPEachCourse{
    margin: 50px;
}
@media (max-width:1000px){
    .SPTitle{
        width: 100%;
        text-align: center;
        margin-left:0;
        font-size: 150%;
    }

}
@media (max-width:400px){
    .SPTitle{
        font-size: 130%;
    } 
}