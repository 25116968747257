*{
    font-family: Poppins, sans-serif;
    margin: 0;
    padding: 0;
}

/* .JourneyParent{ */
    /* padding: 10px 0; */
    /* background-color: #808080; */
/* } */
.JourneyPart1{
    margin: 50px 150px;
    /* padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap; */
    /* background-color: #808080; */
}
.JourneyPart1_container{
    width: 100%;
    padding: 0;
    display: flex;
    /* margin: auto; */
    /* justify-content: center; */
    flex-direction: row;
    flex-wrap: wrap;
}
.JContent1{
    /* height: 400px; */
    /* max-width: 500px;  */
    margin: 30px;
}
.JContent1 h1{
    color: #27227A;
    /* text-align: right; */
    font-size: 30px;
    padding: 30px 0;
    /* margin: 30px; */

}
.JContent1 p{
    font-family: Poppins, sans-serif;
    text-align: justify; 
    padding: 10px 0;
    /* padding-right: 20px; */
    font-size: 18px;
    line-height: 2;
}
/* .JourneyPart1 p{
    text-align: justify; 
    padding: 10px 0;
    padding-right: 20px;
    line-height: 1.8;
    font-size: 16px;
} */
/* .JourneyContent1 h1{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px;
    text-align: center;
    color: #27227A;
    font-size: 25px;

}
.JourneyContent1 p{
    margin: 20px 150px;
    text-align: justify;
    font-size: 15px;
    line-height: 2;
} */

.JourneyImage img{
    float: right;
    margin: 20px;
    margin-left: 50px;
        /* margin-top: 50px; */
    height: 385px;
    width: 300px;
 object-fit: cover;
 border-radius: 10px;
/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
 /* border: #27227A 1px solid; */
/* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
/* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
box-shadow: 0px -10px 30px #ccc;
}
.JourneyPart2{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-wrap: wrap;
    background-color:rgb(226, 227, 229) ;
    
}
.JourneyContent2{
    position: relative;
    width: 400px;
    margin: 50px 30px;
    margin-bottom: 80px;
    
}
.StudentIcon , .GroupIcon{
    width: auto;
    height: auto;
    text-align: center;
    font-size: 70px;
    color: #27227A;
    margin: 10px;
    /* box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.587); */
}
.JourneyContent2 h1{
    color: #27227A;
    padding: 20px;
    text-align: center;
    font-size: 20px;
}
.JourneyContent2 p{
    
    text-align: justify;
    font-size: 18px;
    line-height: 2;
    height: 200px;
    overflow: hidden;
    padding: 10px;
    
}
.journey_content_seminar_block p{
    transition: 2s;
}
.increaseHeight{
    position: absolute;
    right: 0;
    margin: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    color: white;
    border-radius: 50%;
    border: 2px solid #27227A;
    background-color: #27227A;
    text-align: center;
}
.increaseHeight:hover{
    cursor: pointer;
    background-color: transparent;
    color: #27227A;
}
@media (max-width:1200px){
    .JourneyPart1{
        margin: 50px;
    }
}
 @media (max-width:1017px){
    .JourneyPart1{
        margin: 50px;
    }
    
    .JContent1{
        margin: 20px 10px;
    }
    .JContent1 h1{
        text-align: center;
    }
    .JContent1 p{
        
        padding: 20px 10px;
    }
   
 }
 @media (max-width:925px){
    .JourneyContent2{
        height: auto;
        width: auto;
        margin: 15px 20px;
    }
    .JourneyContent2 p{
        height: auto;
        width: auto;
    }
    .increaseHeight{
        display: none;
    }
 }
 @media(max-width:850px)
 {
    .JContent1 p{
        clear: right;
    }
    .JourneyImage{
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    .JourneyImage img{
            margin: 10px;
        }

 }
 @media (max-width:500px){
    .JourneyPart1{
        margin: 10px 20px;
    }
    .JContent1 h1{
        padding: 20px;
        font-size: 150%;
        
    }
    .GroupIcon ,.StudentIcon{
        font-size: 50px;
        margin: 0;
    }
    .JContent1 p{
        
        font-size: 90%;
        line-height: 2;
        padding: 5px 0;
    }
    /* .JourneyImage {
        width: 90%;
        height: auto;
        margin: 10px;
    } */
    .JourneyImage img{
        width: 90%;
            height: 90%;
            margin: 10px;
    }
    
    .JourneyContent2{
        margin: 15px 20px;
        width: auto;
        height: auto;
    }
    .JourneyContent2 p{
        width: auto;
        height: auto;
        font-size: 90%;
    }
       .increaseHeight{
        display: none;
    }
 }