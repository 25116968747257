*{
    font-family: Poppins, sans-serif;
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
  background: #f1f1f1; 
 
}
::-webkit-scrollbar-thumb {
    background: #4b4b4bc5; 
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4b4b4b; 
  }


#Navbar{
    z-index: 10;
    position: fixed;
    /* position: sticky; */
    top:0px;
    left: 0px;
    max-width: 1920px;
    height: 70px;
    
}
.NavbarMain{
    position: relative;
    background-color: #CB2C31;
    height: 80px;
    width: 100vw;
    max-width: 1920px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    /* box-shadow:2px 2px 10px 5px #cb2c316c; */

    /* position: fixed; */
}
.NavbarLogo{
    /* width: 400px; */
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 30px;
}

.NavBarContent{
    position: absolute;
    /* height: 100%; */
    /* width: 50%; */
    /* display: none; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap; 
    margin-right: 50px;
    right: 0;
    
}
.navOption{
    position: relative;
    margin: 10px 20px;
    /* font-size: 100%; */
    cursor: pointer;
    color: #FCF6F5FF;
    font-size: 17px;
    text-decoration: none;
    display: flex;
    transition: 0.1s;
}

.navOption p{
    top:30px;
}


.navOption:hover ,.EachNavCourse:hover{
   transform: scale(1.025);
    color: white;
}
.navBarCourseMenu{
    display: flex;
    flex-direction: column;
}
.navBarCourseParent{
    margin-bottom: 160px;
    height: 0;
}

.navCourseListParent{
    position: absolute;
    /* top: 40px; */
    /* right: 190px; */
    transition: 1s;
    position: absolute;
    list-style: none;
    display: none;   
    /* height: 150px; */
    /* min-width: 100px; */
    z-index: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    background-color: #CB2C31;

}

/* #courseListMenu{
    position: relative;
        display: inline-block;
} */
.courseListMenu{
    position: relative;
    display: inline-block;
    
}
.courseListMenu:hover .navCourseListParent{
    display: block;

}
.EachNavCourse{
    display: block;
    color:#FCF6F5FF;
    width: 120px;
    padding: 10px;
    text-align: left;
    text-decoration: none;
    transition: 0.2s;
    font-size: 15px;

}
/* .EachNavCourse:nth-child(1){
    
} */
.navCourseList{

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
}
.navCourseMenu{
    z-index: 3;
    color: white;
    background-color: rgb(171, 106, 21);
    margin-bottom: 150px;
}



/* .EachNavCourse:hover{
    background-color: #380000;
    text-decoration: dashed;
} */
.MenuIcon{
    display: none;
    position: absolute;
    margin-right: 50px;
    right: 10px;
    color: white;
    font-size: 27px;
    /* background-color: aqua; */
    transition: 0.2s;
    cursor: pointer;
    
}
.MenuIcon:hover{
    color: #380000fb;
    border-radius: 50%;
    
}

.LogoContent{
    color: white;
}  
              
.NavbarLogo img{
    height: 55px;
    width: auto;
    cursor: pointer;
    
}
.sideMenu__list{
    display: none;
    position: absolute;
}
.MenuListParent{
    padding-top: 20px;
    position: absolute;
    /* top:10px; */
    right:-55px;
    /* display: none; */
    z-index: 2;

    
}
.MenuList{
    /* width: 150px; */
    padding: 0 40px;
    /* margin: 0 20px; */
    width: 90px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #cb2c31;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 20px;
    font-weight: normal;
}
.navOptionSideBar{
    position: relative;
    color: white;
    /* margin: 20px 0; */
    margin: 15px 0;
    font-size: 80%;
    text-decoration: none;  
    transition: 0.5s;
}
#courseMenu{
    cursor: pointer;
    color: white;
    margin: 20px 0;
    font-size: 80%;
    text-decoration: none;  
    transition: 0.5s;
}
.NSideBarCourseListParent{
    margin-top: 10px;
}
.NSideBarCourseList{
    width: 120px;
    height: 100px;
    /* padding: 5px 0; */
    display: flex;
    /* background-color: blue; */
    flex-direction: column;
    transition: 0.5s;
   
}
.closeIcon{
    cursor: pointer;
    /* font-size: larger; */
    font-weight: bolder;
    color: white;
    text-align: center;
    margin: auto;
    margin-bottom: 10px;
    
    padding: 5px;
    /* background-color: aquamarine; */
}
.WhatsappIcon{
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 20px;
}
.whatsapp , .queryIcon{
    background-color: transparent;
    /* max-width: 50px;
    max-height: 50px; */
    margin: 10px;
}
.queryIcon{
    
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.queryIcon:hover, .whatsapp:hover{
    cursor: pointer; 
    
}

.whatsapp img , .queryIcon img{
    max-width: 45px;
    max-height: 45px;
}

.QueryPageMenu{
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    right: 100px;
    margin-bottom: 20px;
    
}
@media (max-width:800px) {
    .NavbarMain{
        height: 70px;
        width: 100vw;
    }
    .LogoContent h1{
        font-size: 135%;
    }
    .LogoContent p{
        padding-left: 3px; 
        font-size: 70%;
    }

}
@media (max-width:950px ) {
    .NavBarContent p{
        font-size: 100%;
    }
    .NavBarContent{
        display: none;
    }
    .MenuIcon{
        display: inline-block;
        position: relative;
        margin-right: 20px;
    }
        .MenuIcon:hover .sideMenu__list{
            display: block;
        }
}
@media (max-width:550px){
    .WhatsappIcon{
        margin: 10px;
    }
}
    
    
@media (max-width:400px) {
    .NavbarLogo{
        width: 70%;
        margin-left: 5px;
    }
    .NavbarLogo img{
        width: 100%;
    }

    .MenuIcon{
        font-size: 150%;
        margin-right: 10px;
    }
    .MenuList p{
        font-size: 65%;
        margin: 8px;
        
    }
    .MenuList p Link{
        text-decoration: none;
        color: white;
    }
    
    
}