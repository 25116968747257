/*  */
.SeminarMainParent {
  display: flex;
  justify-content: center;
}

.SeminarParent {
  margin: 30px;
  padding: 30px;
  display: flex;
  justify-content: center;
  border: 2px solid #cb2c31;
  border-radius: 10px;

  width: auto;
  height: 400px;
  /* width: 700px; */
}
.SeminarImage,
.SeminarImage img {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
}

/* .SeminarImage{
    margin: 30px;
} */
.SeminarContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 300px;
  margin: 20px;
}
.SeminarTitle {
  color: #27227a;
  font-size: 20px;
  margin: 10px 0;
}
.SeminarDetail {
  font-size: 17px;
  margin: 20px 0;
  line-height: 2;
  text-align: justify;
}
.SeminarDate {
  font-size: 13px;
  color: #27227a;
  line-height: 2;
}
/*  */
.SeminarSlideParentMain {
  width: 100%;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden;
    background-color: #27227A; */
}

.SeminarSlideParent {
  position: relative;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 764px;
  height: 550px;
  /* background-color: #27227A;  */
  overflow: hidden;
}
.SeminarSlideContainer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  transition: 0.2s;
}

/* .SeminarContainerParent{
    width: auto;
    height: 800px;
    display: flex;
    justify-content: center;
    
} */
/* .SeminarSlideContainer{
    
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    display: flex;
    background-color: #27227A;
    overflow: hidden;
    
} */

/* .SeminarSlideParent{
    position:absolute;
    display: flex;
    left: 0px;
    width: a;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 5s;
    
} */
/* @media(max-width:815px){
    .SeminarParent{
        flex-wrap: wrap;
        height: 700px;
    }
    .SeminarSlideContainer{
        width: 464px;
        height: 810px;
    }
}
@media (max-width:500px){
    .SeminarContent{
        margin: 0;
    }
    .SeminarImage {
        width: 100%;
        height: 40%;
    }
    .SeminarImage img{
        width: 100%;
        height: 100%;
    }
    .SeminarParent{
        padding: 20px;
    }
    .SeminarImage{
        margin: 10px;
    }
    .SeminarSlideContainer{
        width: 464px;
        height: 625px;
    }
} */
