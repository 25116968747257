.smallReviewContainer{
    width: 300px;
    /* height: 500px; */
    height: auto;
    height: 470px;
    border: 2px solid #FAFAFA;
    /* background-color: #FAFAFA; */
    box-sizing: border-box;
    padding: 10px;
    position: relative;
}
.smallReviewTitle{
    width: 100%;
    height: 60px;
    /* padding: 10px; */
    display: flex;
}
.smallPersonImage{

    width: 50px;
    height: 60px;
    
    display: flex;
    align-items:center;
    flex-direction: column;
    justify-content: center;
}
 .smallPersonImage img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.smallPersonName{
    margin: 0 30px ;
    /* width: 150px; */
    display: flex;
    align-items:flex-start;
    flex-direction: column;
    justify-content: center;
}
.smallName{
    font-size: 13px;
    font-weight: bold;
    margin:5px  0;
    padding: 0;
    
    /* font-family: 'Times New Roman', Times, sans-serif; */
}
.smallgoogleIcon{
    height: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 20px;
}
.smallTime{
    margin: 5px 0;
    font-size: 12px;
}
.smallReviewContent{
    margin-top: 20px;
}
.smallReviewContent p{
    font-size: 12.5px;
    margin: 10px 0px;
    /* margin-top: 15px; */
    line-height: 1.5;
    
}
.SeeMorebtnParent{
    display: none;
}
.SeeMorebtn{
    position: relative;
    left: 235px;
    font-size: 20px;
    top:10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #DDDFE4;
    cursor: pointer;
}

